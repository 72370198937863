import ThemesProvider from "./providers/ThemeProvider";
import Routes from "./routes/index";
import { BrowserRouter } from "react-router-dom";

export default function App() {
  return (
    <ThemesProvider>
      <Routes />
    </ThemesProvider>
  );
}
