export const initialQuestions = [
  {
    question:
      "Quantas medalhas o Brasil conquistou em Jogos Olímpicos de Verão?",
    options: ["150", "130", "152"],
    answer: "150",
  },
  {
    question: "Qual é a modalidade que mais trouxe medalhas para o Brasil?",
    options: ["Natação", "Vôlei", "Judô"],
    answer: "Judô",
  },
  {
    question:
      "Em quais edições dos Jogos Olímpicos o Brasil teve recorde de medalhas de ouro?",
    options: [
      "Londres (1948) e Roma (1960)",
      "Rio (2016) e Tóquio (2020)",
      "Barcelona (1992) e Sydney (2000)",
    ],
    answer: "Rio (2016) e Tóquio (2020)",
  },
  {
    question:
      "Em qual Jogos Olímpicos o Brasil teve recorde total de medalhas?",
    options: [
      "Rio - 2016, 19 medalhas",
      "Tóquio - 2020, 21 medalhas",
      "Sydney - 2000, 23 medalhas",
    ],
    answer: "Tóquio - 2020, 21 medalhas",
  },
  {
    question: "Em qual edição o Brasil bateu o recorde de número de atletas?",
    options: [
      "Rio 2016 (465 atletas)",
      "Londres 2012 (460 atletas)",
      "Pequim 2008 (483 atletas)",
    ],
    answer: "Rio 2016 (465 atletas)",
  },
  {
    question: "Qual é o atleta que é tricampeão olímpico?",
    options: [
      "José Roberto Guimarães (vôlei)",
      "Adhemar Ferreira da Silva (atletismo)",
      "Marcelo Ferreira (vela)",
    ],
    answer: "José Roberto Guimarães (vôlei)",
  },
  {
    question:
      "Qual foi a atleta feminina com maior número de participações olímpicas?",
    options: [
      "Jaqueline (vôlei) - 7",
      "Formiga (futebol) - 7",
      "Fabi (vôlei) - 7",
    ],
    answer: "Formiga (futebol) - 7",
  },
  {
    question:
      "Quais atletas masculinos tiveram maior número de participações olímpicas?",
    options: [
      "Adhemar Ferreira (atletismo) e Martine Grael (vela) - 5",
      "Robert Scheidt (vela) e Rodrigo Pessoa (hipismo) - 7",
      "Marcelo Ferreira (vela) e Thaisa (vôlei) - 7",
    ],
    answer: "Robert Scheidt (vela) e Rodrigo Pessoa (hipismo) - 7",
  },
  {
    question: "Qual sede teve recorde no número de modalidades?",
    options: ["Tóquio 2020 - 38", "Londres 2012 - 43", "Rio 2016 - 41"],
    answer: "Rio 2016 - 41",
  },
  {
    question:
      "Qual é o número total de atletas brasileiros medalhistas na história olímpica?",
    options: ["335", "387", "371"],
    answer: "371",
  },
  {
    question: "Quantas medalhas de ouro o Brasil conquistou até hoje?",
    options: ["35", "37", "32"],
    answer: "37",
  },
  {
    question:
      "Em quais edições dos Jogos Olímpicos o Brasil não teve medalha de bronze?",
    options: [
      "Melbourne 1956 e Barcelona 1992",
      "Antuérpia 1920 e Tóquio 1964",
      "Cidade do México 1968 e Munique 1972",
    ],
    answer: "Melbourne 1956 e Barcelona 1992",
  },
  {
    question:
      "Quais foram os porta-bandeiras do Brasil nos Jogos Olímpicos de Tóquio 2020?",
    options: [
      "Yane Marques (Pentatlo moderno) e Rodrigo Pessoa (Hipismo)",
      "Ketleyn Quadros (judô) e Bruninho (vôlei)",
      "Robert Scheidt (Vela) e Sandra Pires (Vôlei de praia)",
    ],
    answer: "Ketleyn Quadros (judô) e Bruninho (vôlei)",
  },
  {
    question:
      "Quem foi o primeiro porta-bandeira do Brasil na história dos Jogos?",
    options: [
      "Guilherme Paraense (Tiro Esportivo)",
      "Sylvio de Magalhães Padilha (Atletismo)",
      "Alfredo Gomes (Atletismo)",
    ],
    answer: "Guilherme Paraense (Tiro Esportivo)",
  },
  {
    question:
      "Qual foi a edição dos Jogos Olímpicos que teve pela primeira vez uma atleta mulher brasileira?",
    options: ["Amsterdã - 1928", "Berlim - 1936", "Los Angeles - 1932"],
    answer: "Los Angeles - 1932",
  },
  {
    question:
      "Quem foi o primeiro atleta olímpico brasileiro a conquistar uma medalha?",
    options: [
      "Adhemar Ferreira (atletismo)",
      "Afrânio Antônio da Costa (tiro)",
      "Affonso Évora (basquete)",
    ],
    answer: "Afrânio Antônio da Costa (tiro)",
  },
  {
    question:
      "Quem foi o único brasileiro a ganhar três medalhas em uma única edição dos Jogos Olímpicos?",
    options: [
      "Isaquias Queiroz (canoagem)",
      "Gustavo Borges (natação)",
      "Ítalo Ferreira (surf)",
    ],
    answer: "Isaquias Queiroz (canoagem)",
  },
  {
    question:
      "Quais eram as atletas mais baixas da delegação brasileira nos Jogos Olímpicos de 2020?",
    options: [
      "Rebeca Andrade e Flávia Saraiva (1,50 de altura)",
      "Flávia Saraiva e Rayssa Leal (1,47 de altura)",
      "Rayssa Leal e Ana Marcela Cunha (1,46 de altura)",
    ],
    answer: "Flávia Saraiva e Rayssa Leal (1,47 de altura)",
  },
  {
    question:
      "Em qual edição dos Jogos Olímpicos o Brasil bateu o recorde de maior delegação feminina da história?",
    options: ["Pequim 2008", "Tóquio 2020", "Rio 2016"],
    answer: "Tóquio 2020",
  },
  {
    question:
      "Quem foi a atleta mais jovem a representar o Brasil em Jogos Olímpicos na história?",
    options: [
      "Rebeca Andrade (ginástica)",
      "Flávia Saraiva (ginástica)",
      "Rayssa Leal (skate)",
    ],
    answer: "Rayssa Leal (skate)",
  },
  {
    question:
      "Quem foi o primeiro atleta olímpico brasileiro a conquistar uma medalha de ouro?",
    options: [
      "Guilherme Paraense (tiro)",
      "Dario Barbosa (tiro)",
      "Afrânio Costa (tiro)",
    ],
    answer: "Guilherme Paraense (tiro)",
  },
  {
    question:
      "Quem é o único atleta brasileiro a conquistar a Medalha Pierre de Coubertin?",
    options: [
      "Vanderlei Cordeiro de Lima (atletismo)",
      "Guilherme Paraense (tiro)",
      "César Cielo (natação)",
    ],
    answer: "Vanderlei Cordeiro de Lima (atletismo)",
  },
  {
    question:
      "Quantas medalhas de bronze o Brasil conquistou até hoje em Jogos Olímpicos?",
    options: ["55", "71", "83"],
    answer: "71",
  },
  {
    question:
      "Quantas medalhas de prata o Brasil conquistou até hoje em Jogos Olímpicos?",
    options: ["36", "41", "42"],
    answer: "42",
  },
  {
    question:
      "Qual foi a edição dos Jogos Olímpicos que o Brasil mais disputou finais?",
    options: ["Londres 2012 (65)", "Pequim 2008 (75)", "Rio 2016 (71)"],
    answer: "Rio 2016 (71)",
  },
  {
    question:
      "Em qual edição dos Jogos Olímpicos o Brasil bateu recorde de atletas femininas?",
    options: ["Rio 2016 (209)", "Atenas 2004 (230)", "Tóquio 2020 (205)"],
    answer: "Rio 2016 (209)",
  },
  {
    question: "Quem foi o maior atleta medalhista Olímpico brasileiro?",
    options: [
      "Robert Scheidt e Torben Grael (vela)",
      "Renato e Vitor Felipe (volei de praia)",
      "Bruno Schmidt e Saymon (vôlei de praia)",
    ],
    answer: "Robert Scheidt e Torben Grael (vela)",
  },
  {
    question: "Quem conquistou a primeira medalha de ouro feminina?",
    options: [
      "Rebeca Andrade (ginastica)",
      "Jackie Silva e Sandra Pires (vôlei de praia)",
      "Ana Marcela Cunha (natação)",
    ],
    answer: "Jackie Silva e Sandra Pires (vôlei de praia)",
  },
  {
    question: "De quem foi a primeira medalha individual feminina?",
    options: [
      "Rayssa Leal (skate)",
      "Adriana Aparecida (atletismo)",
      "Ketleyn Quadros",
    ],
    answer: "Ketleyn Quadros",
  },
  {
    question:
      "Qual atleta conquistou a primeira medalha de ouro individual feminina?",
    options: [
      "Adriana Behar (vôlei de praia)",
      "Sarah Menezes (judô)",
      "Maurren Maggi (atletismo)",
    ],
    answer: "Maurren Maggi (atletismo)",
  },
  {
    question: "Quem foi o primeiro medalhista Olímpico Brasileiro no Surf?",
    options: ["Gabriel Medina", "Ítalo Ferreira", "Filipe Toledo"],
    answer: "Ítalo Ferreira",
  },
  {
    question: "Quem foi o primeiro medalhista Olímpico Brasileiro na natação?",
    options: ["Tetsuo Okamoto", "César Cielo", "Manoel dos Santos"],
    answer: "Tetsuo Okamoto",
  },
  {
    question: "Qual foi a primeira medalha de ouro do Brasil no futebol?",
    options: ["Pequim 2008", "Montreal 1976", "Rio 2016"],
    answer: "Rio 2016",
  },
  {
    question:
      "Quantas medalhas de ouro o Vôlei Masculino Brasileiro já conquistou duarante as edições dos Jogos Olímpicos?",
    options: [
      "4 medalhas (1996, 2004, 2008 e 2016)",
      "3 medalhas (1992, 2004 e 2016)",
      "5 medalhas (1992, 2000, 2016 e 2020)",
    ],
    answer: "3 medalhas (1992, 2004 e 2016)",
  },
  {
    question:
      "Qual brasileira conquistou a primeira medalha no Judô em Jogos Olímpicos?",
    options: ["Sarah Menezes", "Ketleyn Quadros", "Rafaela Silva"],
    answer: "Ketleyn Quadros",
  },
  {
    question:
      "Em qual edição dos Jogos Olímpicos teve a estreia da modalidade Skate?",
    options: ["Rio 2016", "Londres 2012", "Tóquio 2020"],
    answer: "Tóquio 2020",
  },
  {
    question:
      "Em qual edição dos Jogos Olímpicos o Brasil conquistou a primeira medalha de ouro no Vôlei Feminino?",
    options: ["Pequim 2008", "Roma 1960", "Atenas 2004"],
    answer: "Pequim 2008",
  },
  {
    question:
      "Quais são as modalidades que estrearam nos últimos Jogos Olímpicos?",
    options: [
      "Surfe, skate, karatê, escalada esportiva e beisebol/softbol",
      "Surfe, basquete, tiro e boxe",
      "Skate, karatê, tênis de mesa e tiro",
    ],
    answer: "Surfe, skate, karatê, escalada esportiva e beisebol/softbol",
  },
  {
    question:
      "Quem foi a primeira mulher a conquistar uma medalha da modalidade skate nos Jogos Olímpicos?",
    options: ["Pâmela Rosa", "Letícia Bufoni", "Rayssa Leal"],
    answer: "Rayssa Leal",
  },
  {
    question:
      "Qual atleta brasileiro foi a primeira a conquistar uma medalha da modalidade skate nos Jogos Olímpicos?",
    options: ["Pedro Quintas", "Kelvin Hoefler", "Pedro Barros"],
    answer: "Kelvin Hoefler",
  },
];
